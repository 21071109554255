import { currency } from '@/support/String';
import { getStatusLabel, ReportStatus } from '@/support/ReportStatus';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import { Rpc } from '@/models/Rpc';
import { cloneDeep } from 'lodash';
import ReportSettings from '@/components/ReportSettings/ReportSettings.vue';
import { ReportSettingItem } from '@/components/ReportSettings/ReportSettings';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import Planning from '@/views/Reports/Planning/Planning';
import InvestigationDialog from '@/views/Reports/investigation-dialog/InvestigationDialog.vue';
import { Options } from '@/components/mi-dialog/MiDialog';
import ProblemIMGDialog from '@/components/dialog/problem-img-dialog/ProblemIMGDialog.vue';
import ChangeStatusDialog from '@/components/dialog/change-status-dialog/ChangeStatusDialog.vue';
import { userRoles } from '@/models/User';

@Component<Beheer>({
  components: {
    ReportSettings,
    InvestigationDialog,
    ProblemIMGDialog,
    ChangeStatusDialog,
  },
})
export default class Beheer extends Vue {
  public $pageTitle = 'Beheer';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Model('readOnly', { default: false })
  protected readOnly = false;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  protected isLoading = false;

  protected isDisplayingProblemIMGDialog = false;

  protected isDisplayingChangeStatusDialog = false;

  protected isDisplaying = {
    approvalDialog: false,
    investigationDialog: false,
  };

  protected planning = {
    type: '',
    questions: [] as string[],
  };

  protected userRoles = userRoles;

  protected selectedItem: ReportSettingItem | null = null;

  protected newCalculationModel: {[key: string]: any} | null = null;

  protected get workPreparationItems(): ReportSettingItem[] {
    let returnStatus = ReportStatus.WERKVOORBEREIDING_REJECTED;
    if (this.report.isZienswijzeReport) {
      returnStatus = ReportStatus.ZIENSWIJZE_WERKVOORBEREIDING_REJECTED;
    }
    if (this.report.isBezwaarReport) {
      returnStatus = ReportStatus.OBJECTION_WERKVOORBEREIDING_REJECTED;
    }
    if (this.report.isStuwmeerRegelingReport) {
      returnStatus = ReportStatus.SMR_WERKVOORBEREIDING_REJECTED;
    }
    return [
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => true,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Aanvullende gegevens nodig van IMG',
        description: 'Terugzetten naar IMG nav ontbrekende gegevens.',
        dialogTitle: 'Aanvullende gegevens nodig van IMG',
        dialogDescription: 'Wil je het dossier verplaatsen omdat je aanvullende gegevens nodig hebt van IMG?',
        confirmText: 'Ja, verplaatsen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: returnStatus,
        showComment: true,
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => !! this.report.organization,
        title: 'Dossier verwijderen',
        description: 'Het dossier verwijderen',
        dialogTitle: 'Dossier verwijderen',
        dialogDescription: 'Weet u zeker dat u het raport wilt verwijderen?',
        confirmText: 'Ja, verwijderen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('report:delete', {});
          this.$router.push('/reports');
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => true,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: this.$store.state.isServiceOrganization ? 'Dossier terughalen' : 'Dossier teruggeven',
        description: this.$store.state.isServiceOrganization ? 'Dossier terughalen naar werkvoorbereiding en wissen bij het bureau.' : 'Dossier teruggeven aan Werkvoorbereiding',
        dialogTitle: this.$store.state.isServiceOrganization ? 'Dossier terughalen' : 'Dossier teruggeven aan Werkvoorbereiding',
        dialogDescription: this.$store.state.isServiceOrganization ? 'Dossier terughalen naar werkvoorbereiding en wissen bij het bureau.' : 'Dossier teruggeven aan Werkvoorbereiding.<br><br><strong>LET OP! Dossier wordt verwijderd</strong>. ',
        confirmText: this.$store.state.isServiceOrganization ? 'Ja, terughalen' : 'Ja, teruggeven en verwijderen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => await this.rpcPost('werkvoorbereiding:cancel', {}),
      },
    ];
  }

  protected get caseMediatorItems(): ReportSettingItem[] {
    return [
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => true,
        disabled: (item: ReportSettingItem): boolean => ! this.report.isOwnedByBureauRegulier && ! this.report.isOwnedByBureauSMR && ! this.report.hasStatus([ReportStatus.PROBLEM_TCMG]),
        title: 'Naar Zaakbegeleiding',
        description: 'Dossier versturen naar de beheerder van Zaakbegeleiding',
        dialogTitle: 'Naar Zaakbegeleiding',
        dialogDescription: 'Met deze knop verstuur je het dossier naar Zaakbegeleiding. De beheerder van Zaakbegeleiding zullen de desbetreffende zaakbegeleider op de hoogte stellen.',
        confirmText: 'Ja, verplaatsen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: ReportStatus.ZAAKBEGELEIDER_INCOMING,
        showComment: true,
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => ! this.report.hasStatus([ReportStatus.ZAAKBEGELEIDER_INCOMING]),
        title: 'Wachten op Zaakbegeleider',
        description: 'Wanneer het dossier in afwachting is van de zaakbegeleider',
        dialogTitle: 'Wachten op Zaakbegeleider',
        dialogDescription: 'Met deze knop verstuur je het dossier naar Zaakbegeleider zodat deze hem kan oppakken',
        confirmText: 'Ja, verplaatsen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: ReportStatus.ZAAKBEGELEIDER,
        showComment: true,
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => ! this.report.hasStatus([ReportStatus.ZAAKBEGELEIDER_INCOMING, ReportStatus.ZAAKBEGELEIDER]),
        title: 'Terug naar Deskundige',
        description: 'Stuur het dossier terug naar de deskundige zodat deze het weer kan oppakken',
        dialogTitle: 'Terug naar Deskundige',
        dialogDescription: 'Stuur het dossier terug naar de deskundige zodat deze het weer kan oppakken.',
        confirmText: 'Ja, verplaatsen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: ReportStatus.IN_PROGRESS,
        showComment: true,
      },
    ];
  }

  protected get schadeBepalingItems(): ReportSettingItem[] {
    const isServiceOrganization = this.$store.state.isServiceOrganization;
    const isOwnedBySchadeBepaling = this.report.isOwnedBySchadeBepaling;
    const isOnHold = this.report.hasStatus([ReportStatus.VELDWERK_ONHOLD, ReportStatus.ZIENSWIJZE_ONHOLD]);
    const isAborted = this.report.hasStatus([ReportStatus.ABORTED]);

    return [
      this.statusChangeItem(ReportStatus.VELDWERK_ONHOLD, isServiceOrganization, ! isOwnedBySchadeBepaling || isOnHold),
      this.statusChangeItem(ReportStatus.ABORTED, isServiceOrganization, ! isOwnedBySchadeBepaling),
      this.statusChangeItem(ReportStatus.SMR_CREATED, isServiceOrganization, ! isOwnedBySchadeBepaling),
      this.statusChangeItem(ReportStatus.APPROVED, isServiceOrganization, ! isOwnedBySchadeBepaling && ! isAborted),
    ];
  }

  protected get schadeAfhandelingItems(): ReportSettingItem[] {
    const isServiceOrganization = this.$store.state.isServiceOrganization;
    const isOwnedBySchadeBepaling = this.report.isOwnedBySchadeBepaling;
    const isOnHold = this.report.hasStatus([ReportStatus.VELDWERK_ONHOLD, ReportStatus.ZIENSWIJZE_ONHOLD]);
    const isAborted = this.report.hasStatus([ReportStatus.ABORTED]);

    return [
      this.statusChangeItem(ReportStatus.ZIENSWIJZE_ONHOLD, isServiceOrganization, ! isOwnedBySchadeBepaling || isOnHold),
      this.statusChangeItem(ReportStatus.ZIENSWIJZE_APPROVED, isServiceOrganization, ! isOwnedBySchadeBepaling && ! isAborted),
      this.statusChangeItem(ReportStatus.ABORTED, isServiceOrganization, ! isOwnedBySchadeBepaling),
    ];
  }

  protected get bezwaarItems(): ReportSettingItem[] {
    const isServiceOrganization = this.$store.state.isServiceOrganization;
    const isObjectionWVRejected = this.report.hasStatus([ReportStatus.OBJECTION_WERKVOORBEREIDING_REJECTED]);

    return [
      this.statusChangeItem(ReportStatus.OBJECTION_IN_PROGRESS, isServiceOrganization, ! isObjectionWVRejected),
    ];
  }

  protected get planningItems(): ReportSettingItem[] {
    return [
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket']),
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Intrekken',
        description: 'Rapport intrekken.',
        dialogTitle: 'Bevestiging',
        dialogDescription: 'Weet u zeker dat u dit rapport wilt intrekken ?',
        confirmText: 'Ja, rapport intrekken',
        cancelText: 'Nee dat wil ik niet',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          const response = await this.rpcPost('planning:cancel', { sync: true, report: this.report.uuid });
          if (response) {
            this.$store.dispatch('openDialog', this.dialogOptionsCallBackReportSuccess);
          }
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => ! this.report.isOwnedByBureau,
        title: 'Afspraak ging niet door',
        description: 'Geef het dossier terug aan de planning n.a.v. een annulering',
        dialogTitle: 'Afspraak ging niet door',
        dialogDescription: 'Geef het dossier terug aan de planning n.a.v. een annulering. Het dossier wordt opnieuw in de reguliere planning opgepakt',
        confirmText: 'Ja, teruggeven',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          let disabled = item.disabled;
          if (item.disabled && {}.toString.call(item.disabled) == '[object Function]') {
            disabled = (item as any).disabled();
          }

          console.log('disabled', disabled);
          if (disabled) return;
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: ReportStatus.BACK_TO_PLANNING,
        showComment: true,
        approval: 'rejected',
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.report.isClosed && ! this.report.isBezwaarReport,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Vervolgopname',
        description: 'Vraag hier een vervolgopname aan',
        dialogTitle: 'Vervolgopname aanvragen',
        dialogDescription: 'Vraag hier een vervolgopname aan.',
        confirmText: 'Ja, teruggeven',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.planning.type = 'followUpInvestigation';
          this.planning.questions = new Planning().questionsMap[this.planning.type];
          this.isDisplaying.investigationDialog = true;
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.report.isClosed && ! this.report.isBezwaarReport,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Nader onderzoek',
        description: 'Vraag hier een Nader onderzoek aan',
        dialogTitle: 'Nader onderzoek aanvragen',
        dialogDescription: 'Vraag hier een Nader onderzoek aan.',
        confirmText: 'Ja, teruggeven',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.planning.type = 'furtherInvestigation';
          this.planning.questions = new Planning().questionsMap[this.planning.type];
          this.isDisplaying.investigationDialog = true;
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.report.isClosed && ! this.report.isBezwaarReport,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Aanvullend onderzoek',
        description: 'Vraag hier een Aanvullend onderzoek aan',
        dialogTitle: 'Aanvullend onderzoek aanvragen',
        dialogDescription: 'Vraag hier een Aanvullend onderzoek aan.',
        confirmText: 'Ja, teruggeven',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.planning.type = 'moreInvestigation';
          this.planning.questions = new Planning().questionsMap[this.planning.type];
          this.isDisplaying.investigationDialog = true;
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.report.isClosed && ! this.report.isBezwaarReport,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Heropname',
        description: 'Vraag hier een Heropname aan',
        dialogTitle: 'Heropname aanvragen',
        dialogDescription: 'Vraag hier een Heropname aan.',
        confirmText: 'Ja, teruggeven',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.planning.type = 'reInvestigation';
          this.planning.questions = new Planning().questionsMap[this.planning.type];
          this.isDisplaying.investigationDialog = true;
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.report.isClosed && this.allowEdit && this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Heropname Aanmaken',
        description: 'Heropname Aanmaken.',
        dialogTitle: 'Heropname Aanmaken',
        dialogDescription: 'Weet je zeker dat je een heropname wilt aanmaken voor dit dossier?',
        confirmText: 'Ja, aanmaken',
        cancelText: 'Nee dat wil ik niet',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('heropname:create', { report: this.report.case_number });
        },
      },
    ];
  }

  protected get actionItems(): ReportSettingItem[] {
    return [
      {
        dialog_type: 'confirm',
        type: 'switch',
        value: cloneDeep(this.report.is_final),
        visible: (item: ReportSettingItem): boolean => {
          if (this.$store.state.isServiceOrganization) {
            return false;
          }

          if (! this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles)) {
            return false;
          }

          return this.report.isOwnedByBureau;
        },
        disabled: (item: ReportSettingItem): boolean => this.readOnly || ! this.report.isOwnedByBureau,
        title: 'Defintieve PDF',
        description: 'PDF rapport (un)locken voor bewerking',
        label: {
          true: 'Definitief',
          false: 'Bewerkbaar',
        },
        dialogTitle: 'Defintieve PDF',
        dialogDescription: this.report.is_final ? 'Dossier van definitieve status afhalen' : 'Dossier op definitieve status zetten',
        confirmText: 'Ja, verplaatsen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('report:final', { is_final: ! item.value });
        },
      },
      {
        dialog_type: 'confirm',
        type: 'switch',
        value: cloneDeep(this.report.is_offline),
        visible: (item: ReportSettingItem): boolean => {
          if (this.$store.state.isServiceOrganization) {
            return false;
          }

          return this.report.isEditableByExpert || this.report.isOffline();
        },
        disabled: (item: ReportSettingItem): boolean => this.readOnly || ! this.report.offline_user,
        title: 'Offline switch',
        description: 'Dossier online/offline overrulen',
        label: {
          true: 'Offline',
          false: 'Online',
        },
        dialogTitle: 'Offline switch',
        dialogDescription: 'Dossier online/offline overrulen',
        confirmText: `Zet: ${! this.report.is_offline ? 'Offline' : 'Online'}`,
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.report.update({ is_offline: ! item.value }).catch((error: AxiosError) => {
            ErrorHandler.network(error);
          });
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => ! this.report.isClosed,
        disabled: (item: ReportSettingItem): boolean => this.readOnly, // FIXME: Needs content
        // #FIXME: Fix sentances
        title: 'Probleem IMG',
        description: 'Maak melding van Probleem IMG',
        dialogTitle: 'Probleem IMG',
        dialogDescription: 'Weet u zeker dat u de melding Probleem IMG wilt aanmaken?',
        confirmText: 'Ja, Probleem IMG',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.isDisplayingProblemIMGDialog = true;
        },
      },
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.isServiceOrganization && ! this.report.isClosed,
        disabled: (item: ReportSettingItem): boolean => this.readOnly,
        title: 'Opdracht afbreken',
        description: 'De opdracht afbreken',
        dialogTitle: 'Opdracht afbreken',
        dialogDescription: 'Weet u zeker dat u de opdracht wilt afbreken?',
        confirmText: 'Ja, opdracht afbreken',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplaying.approvalDialog = true;
        },
        status: ReportStatus.ABORTED,
        showComment: true,
      },
    ];
  }

  protected get atabixActions(): ReportSettingItem[] {
    return [
      {
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix,
        disabled: (item: ReportSettingItem): boolean => false,
        title: 'Status aanpassen',
        description: 'Verander de status van het rapport naar de nieuw aangegeven status.',
        dialogTitle: 'Status aanpassen',
        dialogDescription: 'Verander de status van het rapport naar de nieuw aangegeven status. De status wordt in alle omgevingen waar het rapport voorkomt aangepast.',
        confirmText: 'Ja, aanpassen',
        cancelText: 'Annuleren',
        changeStatus: true,
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.selectedItem = item;
          this.isDisplayingChangeStatusDialog = true;
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix && this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => false,
        title: 'Dossier Synchroniseren',
        description: 'Rapport wordt opnieuw gesynchroniseerd met de gegevens uit de deskundige bureau, terug naar IMG.',
        dialogTitle: 'Dossier Synchroniseren',
        dialogDescription: 'Weet u zeker dat u het raport wilt synchroniseren?',
        confirmText: 'Ja, synchroniseren',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('report:reload', {});
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix,
        disabled: (item: ReportSettingItem): boolean => false,
        title: 'Doorlooptijden herberekenen',
        description: 'Weet je zeker dat je de doorlooptijden voor dit rapport opnieuw wilt uitrekenen?',
        dialogTitle: 'Doorlooptijden herberekenen',
        dialogDescription: 'Weet je zeker dat je de doorlooptijden voor dit rapport opnieuw wilt uitrekenen?',
        confirmText: 'Ja, herbereken',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('management-info:reset', {});
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix && this.$store.state.isServiceOrganization,
        disabled: (item: ReportSettingItem): boolean => false,
        title: 'Rapport inleveren',
        description: 'Weet je zeker dat je dit rapport opnieuw wilt inleveren?',
        dialogTitle: 'Rapport inleveren',
        dialogDescription: 'Weet je zeker dat je dit rapport opnieuw wilt inleveren?',
        confirmText: 'Ja, inleveren',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('report:hand-in', { report: this.report.uuid });
        },
      },
      {
        // dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix,
        disabled: (item: ReportSettingItem): boolean => ! this.report.isPre2021ReportTypes && ! this.report.isPre2021CalculationModel,
        title: 'Rapport converteren',
        description: 'Weet je zeker dat je dit rapport wilt converteren naar het nieuwe model?',
        dialogTitle: 'Rapport converteren',
        dialogDescription: 'Weet je zeker dat je dit rapport wilt converteren naar het nieuwe model',
        confirmText: 'Ja, converteren',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          this.newCalculationModel = await this.rpcPost('calculation-model:dry-run-convert-to-v5', { report: this.report.uuid });
          if (this.newCalculationModel) {
            this.$store.dispatch('openDialog', this.confirmDialogOptions);
          }
        },
      },
      {
        dialog_type: 'confirm',
        type: 'button',
        icon: 'exit_to_app',
        visible: (item: ReportSettingItem): boolean => this.$store.state.Auth.isAtabix,
        disabled: (item: ReportSettingItem): boolean => false,
        title: 'Dossier verwijderen',
        description: 'Het dossier verwijderen',
        dialogTitle: 'Dossier verwijderen',
        dialogDescription: 'Weet u zeker dat u het raport wilt verwijderen?',
        confirmText: 'Ja, verwijderen',
        cancelText: 'Annuleren',
        confirmAction: async (item: ReportSettingItem): Promise<void> => {
          await this.rpcPost('report:delete', {});
          this.$router.push('/reports');
        },
      }];
  }

  protected get workPreparationBackStatus() {
    if (this.report.isZienswijzeReport) {
      return 'zienswijze_werkvoorbereiding_rejected';
    }

    if (this.report.isBezwaarReport) {
      return 'objection_werkvoorbereiding_rejected';
    }

    if (this.report.isStuwmeerRegelingReport) {
      return 'smr_werkvoorbereiding_rejected';
    }

    return 'werkvoorbereiding_rejected';
  }

  private statusChangeItem(statusKey: string, visible: boolean, disabled: boolean): ReportSettingItem {
    const statusLabel = getStatusLabel(statusKey);
    return {
      type: 'button',
      icon: 'exit_to_app',
      visible: (item: ReportSettingItem): boolean => visible,
      disabled: (item: ReportSettingItem): boolean => disabled,
      title: `${statusLabel}`,
      description: `Zet het dossier in ${statusLabel}`,
      dialogTitle: `${statusLabel}`,
      dialogDescription: `Zet het dossier in ${statusLabel}`,
      confirmText: 'Ja, verplaatsen',
      cancelText: 'Annuleren',
      confirmAction: async (item: ReportSettingItem): Promise<void> => {
        this.selectedItem = item;
        this.isDisplaying.approvalDialog = true;
      },
      status: statusKey,
      showComment: true,
    };
  }

  protected refreshPage(): void {
    // Necessary for 'put file online/offline'-bar
    window.location.reload();
  }

  protected async rpcPost(signature: string, body: {}): Promise<any | null> {
    if (! signature) {
      return null;
    }

    const payload: { [key: string]: any } = {
      signature,
      body: { ...{ report: this.report.case_number }, ...body },
    };

    return await new Rpc().rpcPost(payload).catch((error: AxiosError) => {
      ErrorHandler.network(error);
      return null;
    });
  }

  protected async submitInvestigation() {
    this.isLoading = true;
    await new ReportModel(this.report).update(new Planning().payloadMap[this.planning.type]).then(() => {
      this.reload();
      this.planning.type = '';
      this.planning.questions = [];
    });

    this.isLoading = false;
  }

  protected reload() {
    this.$emit('reload');
  }

  protected get dialogOptionsCallBackReportSuccess(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Het rapport is ingetrokken',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get confirmDialogOptions(): Options {
    return {
      title: 'Bevestigen',
      text: `Weet je het heel zeker dat je dit dossier wilt converteren? Bedrag veranderd van €${currency(this.report.payout_total || '')} naar €${currency(this.newCalculationModel?.total_payout || '')}`,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          action: async (): Promise<void> => {
            const response = await this.rpcPost('calculation-model:convert-to-v5', { report: this.report.uuid });
            if (! response) {
              return;
            }

            this.refreshPage();
          },
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
        },
      },
    };
  }
}
