import { Vue, Component, Prop } from 'vue-property-decorator';
import { sanitizeString } from '@/support/String';
import { cloneDeep } from 'lodash';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<ReportSettings>({})
export default class ReportSettings extends Vue {
  @Prop()
  protected item!: ReportSettingItem;

  protected selectedItem: ReportSettingItem | null = null;

  protected switchValue = false;

  protected mounted(): void {
    if (this.item.type === 'switch') {
      this.switchValue = cloneDeep(!! this.item.value);
    }
  }

  protected isVisible(item: ReportSettingItem): boolean | isVisibleFunction {
    if (typeof item.visible !== 'undefined') {
      if (this.isFunction(item.visible) && typeof item.visible !== 'boolean') {
        return (item.visible(item) as unknown) as isVisibleFunction;
      }
      return item.visible as boolean;
    }

    return true;
  }

  protected isDisabled(item: ReportSettingItem): boolean | isDisabledFunction {
    if (typeof item.disabled !== 'undefined') {
      if (this.isFunction(item.disabled) && typeof item.disabled !== 'boolean') {
        return (item.disabled(item) as unknown) as isDisabledFunction;
      }
      return item.disabled as boolean;
    }

    return true;
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected isFunction(object: any): boolean {
    return object && {}.toString.call(object) == '[object Function]';
  }

  protected isDisplayingDialog(item: ReportSettingItem): void {
    this.selectedItem = item;

    this.$store.dispatch('openDialog', this.confirmDialogOptions);
  }

  protected get confirmDialogOptions(): Options {
    return {
      title: this.selectedItem?.dialogTitle || 'Bevestigen',
      text: this.selectedItem?.dialogDescription || '',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.selectedItem?.confirmText || 'Ok',
          action: async (): Promise<void> => {
            const response = await this.selectedItem?.confirmAction(this.selectedItem);
            if (! response) {
              return;
            }

            this.$emit('refresh');
          },
        },
        cancel: {
          text: this.selectedItem?.cancelText || 'Annuleren',
          color: 'text-light',
          action: () => {
            if (this.selectedItem?.type === 'switch') {
              this.switchValue = ! this.switchValue;
            }
          },
        },
      },
    };
  }
}

export interface ReportSettingItem {
  dialog_type?: 'confirm';
  type: 'button' | 'switch';
  icon?: string;
  visible: isVisibleFunction | boolean;
  disabled: isDisabledFunction | boolean;
  value?: boolean;
  label?: {
    true: string;
    false: string;
  };
  title: string;
  description?: string;
  dialogTitle: string;
  dialogDescription?: string;
  confirmAction: (item: ReportSettingItem) => Promise<void>;
  confirmText?: string;
  cancelText?: string;
  status?: string;
  showComment?: boolean;
  changeStatus?: boolean;
  approval?: 'approved' | 'rejected';
}

type isVisibleFunction = (item: ReportSettingItem) => boolean;
type isDisabledFunction = (item: ReportSettingItem) => boolean;
