import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { excludePlanningStatuses, ReportStatusMap } from '@/support/ReportStatus';
import { Rpc } from '@/models/Rpc';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<ChangeStatusDialog>({})
export default class ChangeStatusDialog extends Vue {
  @Prop()
  protected statusses!: ReportStatusMap[];

  @Prop()
  protected report!: Report;

  @Prop({ default: 'Dossier verplaatsen' })
  protected title!: string;

  @Prop()
  protected subtitle!: string;

  protected isLoading = false;

  protected defaultStatusses: ReportStatusMap[] = excludePlanningStatuses();

  protected selectedStatus = '';

  protected mounted() {
    if (this.statusses) {
      this.defaultStatusses = this.statusses;
    }
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('reloadReport');
  }

  protected updateReport() {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload = {
      signature: 'report:status',
      body: {
        report: this.report.case_number,
        status: this.selectedStatus,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
