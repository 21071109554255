var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel report-beheer" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.isLoading
            ? _c(
                "v-flex",
                { attrs: { sm12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            md6: "",
                            "pr-4": _vm.$vuetify.breakpoint.mdAndUp
                          }
                        },
                        [
                          !_vm.report.isClosed
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v("Werkvoorbereiding")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item__list" },
                                      [
                                        _vm._l(
                                          _vm.workPreparationItems,
                                          function(item, index) {
                                            return [
                                              _c("ReportSettings", {
                                                key: index,
                                                attrs: { item: item },
                                                on: { refresh: _vm.refreshPage }
                                              })
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ]),
                                  _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v("Planning")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item__list" },
                                      [
                                        _vm._l(_vm.planningItems, function(
                                          item,
                                          index
                                        ) {
                                          return [
                                            _c("ReportSettings", {
                                              key: index,
                                              attrs: { item: item },
                                              on: { refresh: _vm.refreshPage }
                                            })
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]),
                                  _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v("Zaakbegeleiding")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item__list" },
                                      [
                                        _vm._l(_vm.caseMediatorItems, function(
                                          item,
                                          index
                                        ) {
                                          return [
                                            _c("ReportSettings", {
                                              key: index,
                                              attrs: { item: item },
                                              on: { refresh: _vm.refreshPage }
                                            })
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c("v-flex", { attrs: { sm12: "" } }, [
                                        _c("h3", { staticClass: "mb-2" }, [
                                          _vm._v("Schadebepaling")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "item__list" },
                                          [
                                            _vm._l(
                                              _vm.schadeBepalingItems,
                                              function(item, index) {
                                                return [
                                                  _c("ReportSettings", {
                                                    key: index,
                                                    attrs: { item: item },
                                                    on: {
                                                      refresh: _vm.refreshPage
                                                    }
                                                  })
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c("v-flex", { attrs: { sm12: "" } }, [
                                        _c("h3", { staticClass: "mb-2" }, [
                                          _vm._v("Schadeafhandeling")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "item__list" },
                                          [
                                            _vm._l(
                                              _vm.schadeAfhandelingItems,
                                              function(item, index) {
                                                return [
                                                  _c("ReportSettings", {
                                                    key: index,
                                                    attrs: { item: item },
                                                    on: {
                                                      refresh: _vm.refreshPage
                                                    }
                                                  })
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c("v-flex", { attrs: { sm12: "" } }, [
                                        _c("h3", { staticClass: "mb-2" }, [
                                          _vm._v("Bezwaar")
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "item__list" },
                                          [
                                            _vm._l(_vm.bezwaarItems, function(
                                              item,
                                              index
                                            ) {
                                              return [
                                                _c("ReportSettings", {
                                                  key: index,
                                                  attrs: { item: item },
                                                  on: {
                                                    refresh: _vm.refreshPage
                                                  }
                                                })
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { sm12: "" } }, [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v("Acties")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "item__list" },
                                  [
                                    _vm._l(_vm.actionItems, function(
                                      item,
                                      index
                                    ) {
                                      return [
                                        _c("ReportSettings", {
                                          key: index,
                                          attrs: { item: item },
                                          on: { refresh: _vm.refreshPage }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm.$store.state.Auth.isAtabix
                                ? _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v("Atabix Support")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item__list" },
                                      [
                                        _vm._l(_vm.atabixActions, function(
                                          item,
                                          index
                                        ) {
                                          return [
                                            _c("ReportSettings", {
                                              key: index,
                                              attrs: { item: item },
                                              on: { refresh: _vm.refreshPage }
                                            })
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDisplaying.approvalDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplaying.approvalDialog,
                callback: function($$v) {
                  _vm.$set(_vm.isDisplaying, "approvalDialog", $$v)
                },
                expression: "isDisplaying.approvalDialog"
              }
            },
            [
              _vm.isDisplaying.approvalDialog
                ? _c("ApprovalDialog", {
                    attrs: {
                      report: _vm.report,
                      title: _vm.selectedItem.dialogTitle,
                      status: _vm.selectedItem.status,
                      showComment: _vm.selectedItem.showComment,
                      approval: _vm.selectedItem.approval,
                      role: "manager"
                    },
                    on: { reportUpdated: _vm.refreshPage },
                    model: {
                      value: _vm.isDisplaying.approvalDialog,
                      callback: function($$v) {
                        _vm.$set(_vm.isDisplaying, "approvalDialog", $$v)
                      },
                      expression: "isDisplaying.approvalDialog"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplaying.investigationDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "min-width": "80%" },
              model: {
                value: _vm.isDisplaying.investigationDialog,
                callback: function($$v) {
                  _vm.$set(_vm.isDisplaying, "investigationDialog", $$v)
                },
                expression: "isDisplaying.investigationDialog"
              }
            },
            [
              _c(
                "InvestigationDialog",
                {
                  attrs: { type: _vm.planning.type, report: _vm.report },
                  on: { submitted: _vm.submitInvestigation },
                  model: {
                    value: _vm.isDisplaying.investigationDialog,
                    callback: function($$v) {
                      _vm.$set(_vm.isDisplaying, "investigationDialog", $$v)
                    },
                    expression: "isDisplaying.investigationDialog"
                  }
                },
                [
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "v-card__text scrollContainer" },
                      [
                        _vm.report
                          ? _c("DynamicForm", {
                              attrs: {
                                reportId: _vm.report.uuid,
                                reportType: _vm.report.type.uuid,
                                planningQuestions: true,
                                sections: [123],
                                appendKeys: _vm.planning.questions,
                                hasAutosave: true
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplayingProblemIMGDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingProblemIMGDialog,
                callback: function($$v) {
                  _vm.isDisplayingProblemIMGDialog = $$v
                },
                expression: "isDisplayingProblemIMGDialog"
              }
            },
            [
              _c("ProblemIMGDialog", {
                attrs: { report: _vm.report },
                on: { reload: _vm.reload },
                model: {
                  value: _vm.isDisplayingProblemIMGDialog,
                  callback: function($$v) {
                    _vm.isDisplayingProblemIMGDialog = $$v
                  },
                  expression: "isDisplayingProblemIMGDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplayingChangeStatusDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingChangeStatusDialog,
                callback: function($$v) {
                  _vm.isDisplayingChangeStatusDialog = $$v
                },
                expression: "isDisplayingChangeStatusDialog"
              }
            },
            [
              _c("ChangeStatusDialog", {
                attrs: {
                  report: _vm.report,
                  title: _vm.selectedItem.title,
                  subtitle: _vm.selectedItem.description
                },
                on: { reloadReport: _vm.reload },
                model: {
                  value: _vm.isDisplayingChangeStatusDialog,
                  callback: function($$v) {
                    _vm.isDisplayingChangeStatusDialog = $$v
                  },
                  expression: "isDisplayingChangeStatusDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }