var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible(_vm.item)
    ? _c(
        "v-layout",
        {
          staticClass: "reportsetting__container list__item",
          class: { disabled: _vm.isDisabled(_vm.item) },
          attrs: { wrap: "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "", "pa-0": "" } }, [
            _vm.item.type === "button"
              ? _c(
                  "span",
                  {
                    staticClass: "list__item--link",
                    on: {
                      click: function($event) {
                        _vm.item.dialog_type === "confirm"
                          ? _vm.isDisplayingDialog(_vm.item)
                          : _vm.item.confirmAction(_vm.item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-center": "", row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs10: "" } },
                          [
                            _vm.item.title
                              ? [
                                  _c("span", {
                                    staticClass: "item__link capital",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitizeString(_vm.item.title)
                                      )
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm.item.description
                              ? [
                                  _c("span", {
                                    staticClass: "item__sub",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitizeString(_vm.item.description)
                                      )
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass:
                              "text-xs-right item__icon item__icon--action",
                            attrs: { xs2: "" }
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.item.icon))])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.type === "switch"
              ? _c(
                  "span",
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-center": "", row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.item.title
                              ? [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitizeString(_vm.item.title)
                                      )
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _vm.item.description
                              ? [
                                  _c("span", {
                                    staticClass: "item__sub",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitizeString(_vm.item.description)
                                      )
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "mt-0",
                              attrs: {
                                color: "#837f16",
                                "hide-details": "",
                                label: _vm.switchValue
                                  ? _vm.item.label.true
                                  : _vm.item.label.false
                              },
                              on: {
                                change: function($event) {
                                  _vm.item.dialog_type === "confirm"
                                    ? _vm.isDisplayingDialog(_vm.item)
                                    : _vm.item.confirmAction(_vm.item)
                                }
                              },
                              model: {
                                value: _vm.switchValue,
                                callback: function($$v) {
                                  _vm.switchValue = $$v
                                },
                                expression: "switchValue"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }